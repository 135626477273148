import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { ErrorComponent } from '../components/Error'
import { WritingPageQuery } from '../generated/graphql'
// @ts-ignore
import BgImage from '../blog-bg.png'
import { WritingPageContainer } from '../containers/writing'

const WritingPage = ({
  data,
  location,
}: PageProps<WritingPageQuery, { id: string }>) => {
  if (!data.blogs) {
    return <ErrorComponent message="Unable to load Page" />
  }
  return <WritingPageContainer data={data} location={location} />
}
// This is the page query that connects the data to the actual component. Here you can query for any and all fields
// you need access to within your code. Again, since Gatsby always queries for `id` in the collection, you can use that
// to connect to this GraphQL query.
export const query = graphql`
  query WritingPage {
    blogs: allContentfulBlogPost(
      filter: { node_locale: { eq: "en-US" } }
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        id
        title
        slug
        publishDate(formatString: "MMM Do, YYYY")
        author {
          name
        }
        readingTime
        body {
          childMarkdownRemark {
            excerpt(pruneLength: 500)
          }
        }
      }
    }
  }
`

export default WritingPage
